.home-logo {
  /* max-height: 80%; */
  min-width: 800px;
  width: 100%;
  position: absolute;
  overflow: hidden;

  /* border: 1px solid red; */
}

.home-wrapper {
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* border: 1px solid blue; */
  overflow: hidden;
  font-size: 3em;
  color: white;
}
