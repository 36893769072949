/* Layout */
.contact-flex {
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 1em;
  min-width: 100%;
}
.contact-flexbox {
  min-width: 380px;
  width: 380px;
  margin-bottom: 40px;
}

.p {
  padding-bottom: auto;
  padding-top: auto;
  display: inline-block;
}
/* Images */
.infos-svg {
  height: 1.4em;
  margin-right: 5px;
  vertical-align: middle;
}
.plan {
  max-width: 100%;
}

/* Form - fields */

.contact-field {
  position: relative;
  max-width: 400px;
}
.contact-input {
  display: inline-block;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 350px;
  padding: 10px 20px 10px 8px;
  border: 1px solid #b7b7b7;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  font: normal 16px / normal Tahoma, Geneva, sans-serif;
  color: rgba(0, 0, 0, 1);
  -o-text-overflow: clip;
  text-overflow: clip;
  background: rgba(237, 237, 237, 1);
  margin-bottom: 1.5em;
}

.contact-input:focus {
  border: 2px solid #000000;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px;
  outline: none;
}

textarea {
  resize: none;
  width: 350px;
}

/* Form - Button */
.form-btn {
  margin-top: 8px;
  margin-bottom: 10px;
  display: inline-block;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  cursor: pointer;
  padding: 10px 20px;
  border: 1px solid rgba(0, 0, 0, 0.9);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  font: normal 16px / normal Arial, Helvetica, sans-serif;
  color: rgba(255, 255, 255, 0.9);
  -o-text-overflow: clip;
  text-overflow: clip;
  background: #545454;
  text-shadow: -1px -1px 0 rgba(15, 73, 168, 0.66);
  -webkit-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
  -moz-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 300ms cubic-bezier(0.42, 0, 0.58, 1);
}

.form-btn:hover {
  border: 1px solid rgba(43, 43, 43, 0.9);
  background: #757575;
}

.form-btn:active {
  border: 1px solid rgba(35, 35, 35, 0.9);
  color: rgba(0, 0, 0, 0.9);
  background: rgba(214, 214, 214, 0.9);
  text-shadow: none;
}

/* Form - Error messages */
.contact-form-error {
  color: rgb(180, 0, 0);
  font-size: 0.8em;

  float: right;
  bottom: 4px;
  position: absolute;
  margin-top: 2px;
}
#message-er,
#name-er,
#phone-er,
#email-er {
  display: none;
}

@media screen and (max-width: 420px) {
  .contact-flexbox {
    min-width: 320px;
    width: 320px;
  }
  .contact-input {
    width: 290px;
  }
}
