/* 0 - Custom fonts */
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat.ttf');
}
/* 1 - body */
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(230, 230, 230);
  background-image: url(./assets/img/bg-test2.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: hidden;
}

/*  - Headlines */
h1,
h2,
h3,
h4 {
  font-family: 'Montserrat';
  color: white;
  font-weight: lighter;
}
h1 {
  margin-left: 20px;
  margin-bottom: 0;
  font-size: 3.5em;
}
h2 {
  margin-left: 20px;
  margin-top: 0;
  font-size: 2.5em;
}
h3 {
  color: rgb(88, 88, 88);
  font-size: 3em;
  margin-top: 0.5em;
  font-family: 'Montserrat', sans-serif;
}
h4 {
  color: rgb(31, 31, 31);
  margin-top: 0;
  text-align: center;
  margin-left: 0;
  font-size: 1.2em;
  font-family: 'Montserrat', sans-serif;
}

/* - Forms & Buttons */
button:focus,
button:active {
  border: none;
  outline: none;
}
/* - Page */
.page {
  background-color: white;
  position: absolute;
  bottom: 0%;
  left: -100%;
  top: 8em;
  width: 85%;
  margin-top: 3em;
  border-radius: 0px 15px 0px 0px;
  transition: all 1s ease-out;
  transition-delay: 1s;
  padding: 0px;
  font-family: 'Montserrat', sans-serif;
}
.page-title {
  /*   border: 1px solid green; */
  position: absolute;
  z-index: 2;
  width: calc(85% - 250px);
  margin-left: auto;
  margin-right: auto;
  left: -20px;
  right: 0;
  height: 7em;
  background-image: linear-gradient(
    to bottom,
    rgb(255, 255, 255) 90%,
    rgba(255, 255, 255, 0)
  );
}
.page-sub-title {
  padding-top: 10px;
}
.page-content {
  /*   border: 1px solid red; */
  background-color: white;
  z-index: 1;
  display: block;
  position: absolute;
  width: calc(85% - 250px);
  bottom: 0;
  top: 4.8em;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
  /* padding-top: 1em; */
  padding-bottom: 1em;
  left: 0;
  right: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: rgb(0, 0, 0) rgba(224, 224, 224, 0);
  scrollbar-width: thin;
  scroll-behavior: smooth;
  font-size: 1.2em;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.page-content::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

.page-content::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0);
}
.page-content::-webkit-scrollbar-track {
  background: rgb(251, 176, 59, 0);
}
/* Content */
p {
  text-align: justify;
  text-justify: auto;
  padding-top: 0.5em;
}
p.highlighted {
  font-size: 1.2em;
}
.underline {
  text-decoration: underline;
}
.button-text {
  border: none;
  font-family: 'Montserrat', sans-serif;
  background: none;
  cursor: pointer;
  font-size: 1em;
  margin: 0;
  padding: 0;
  color: grey;
  text-decoration: underline;
}
.button-text:hover {
  color: black;
  text-decoration: none;
}

/* - Responsive */
@media screen and (max-width: 1400px) {
  h3 {
    font-size: 2em;
  }
  .page-title {
    width: calc(85% - 100px);
    height: 5em;
  }
  .page-content {
    width: calc(85% - 100px);
    top: 3.5em;
  }
  .page {
    top: 6.5em;
  }
}
@media screen and (max-width: 1000px) {
  .page-title {
    width: calc(85% - 50px);
  }
  .page-content {
    width: calc(85% - 50px);
  }
}

@media screen and (max-width: 850px) {
  h1 {
    font-size: 2.5em;
    font-weight: 300;
  }
  h2 {
    font-size: 1.6em;
  }
  h3 {
    font-size: 2em;
  }
  .page {
    top: 6em;
  }
  .page-title {
    width: 85%;
  }
  .page-content {
    width: 85%;
  }
}
@media screen and (max-width: 600px) {
  h1 {
    font-size: 2em;
    margin-top: 80px;
    text-align: center;
    margin-left: 0;
  }
  h2 {
    font-size: 1.4em;
    text-align: center;
    margin-left: 0;
  }
  h3 {
    font-size: 1.7em;
    text-align: center;
    background-color: black;
    color: white;
    margin: 0px;
    padding-top: 0.4em;
  }
  .page {
    top: 8em;
    width: 100%;
    border-radius: 0px;
  }
  .page-title {
    background-color: black;
    width: 100%;
    padding-bottom: auto;
    padding-top: auto;
    left: 0px;
    height: 3.5em;
    background-image: none;
  }

  .page-content {
    top: 3.5em;
    font-size: 1em;
  }
}

@media screen and (max-width: 440px) {
  h1 {
    font-size: 1.7em;
    margin-top: 80px;
    text-align: center;
    margin-left: 0;
  }
  h2 {
    font-size: 1.2em;
    text-align: center;
    margin-left: 0;
  }
  h3 {
    font-size: 1.4em;
    padding-top: 0.6em;
  }
  .page {
    top: 7em;
  }
  .page-content {
    width: 90%;
    top: 3.5em;
    line-height: 1.4em;
  }
}
