.mentions-bg {
  visibility: hidden;
  background-color: rgb(37, 37, 37, 0.7);
  z-index: 11;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  transform: translateZ(0);
}

.mentions-cont {
  background-color: white;
  position: absolute;
  margin: auto auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 85%;
  overflow-y: auto;
  height: 90%;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 5px;
}
.sub-title {
  margin-bottom: 1em;
}
.mentions {
  margin-top: 25px;
  font-size: 2em;
  background-color: white;
  color: black;
}
.close {
  position: absolute;
  right: 15px;
  top: 0px;
  width: 32px;
  height: 32px;
  opacity: 1;
  border: hidden;
  cursor: pointer;
  background-color: transparent;
}
.close:hover {
  opacity: 0.5;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 4px;
  background-color: #333;
  border: hidden;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
