#nav {
  background-color: rgb(0, 0, 0);
  height: 100%;
  width: 376px;
  display: flex;
  align-content: center;
  overflow: hidden;
  transition: 0.5s;
  position: fixed;
  left: 100%;
  top: 0;
  z-index: 4;
}

.nav-list {
  list-style: none;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 2em;
  text-align: right;
  /* border: 1px solid green; */
}

.nav-item {
  text-align: right;
  font-size: 2em;
  font-family: 'Montserrat';
  font-weight: lighter;
  color: white;
  letter-spacing: 0.1em;
  width: fit-content;
  border: 0px;
  background-color: rgb(0, 0, 0);
  cursor: pointer;
  z-index: 21;
  transition: 0.5s;
  transition-delay: 0s;
  padding-bottom: 1em;
  /* border: 1px solid blue; */
}
.nav-item:hover {
  color: rgb(179, 179, 179) !important;
}
.nav-foot {
  position: absolute;
  bottom: 0;
  display: block;
  color: white;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  margin-bottom: 1em;
  /* border: 1px solid red; */
  text-align: center;
}
.nav-mentions {
  font-weight: lighter;
  border: hidden;
  color: white;
  text-decoration: underline;
  background-color: black;
  cursor: pointer;
  font-size: 1em;
}
.nav-mentions:hover {
  text-decoration: none;
}

@media screen and (max-width: 850px) {
  .nav-list {
    margin-right: auto;
    margin-left: auto;
  }
  .nav-item {
    font-size: 3em;
  }
}
@media screen and (max-width: 500px) {
  .nav-item {
    font-size: 2em;
    padding-right: 1em;
  }
}
