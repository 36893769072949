.competences-list {
  padding-left: 1em;
}
.competences-list-item {
  padding-left: 0;
  padding: 0.2em;
  text-align: justify;
  text-justify: auto;
  margin-bottom: 0.6em;
}
.muted {
  color: rgb(87, 87, 87);
}
